import React from 'react'
import { graphql } from "gatsby"

import TextTemplate from "./textTemplate"
import Hero from "./common/heroSection"
import ReadyToNextStep from './common/readyToNextStep'
import Layout from "../components/layout"

export const query = graphql`
  query faq_inner_page($slug: String!) {
    menImage: file(relativePath: { eq: "menImageCrop.png" }) {
      childImageSharp {
        fluid(quality: 0, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allStrapiFaq {
      nodes {
        title
        subTitle
      }
    }
    allStrapiFaqInnerPage: strapiFaqInnerPage(slug: { eq: $slug }) {
      title
      content
      featured_image {
        localFile {
          childImageSharp {
            fluid(quality: 10, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const FaqPages = ({ data }) => {
  const { nodes } = data.allStrapiFaq
  const menImage = data.menImage.childImageSharp.fluid
  const faqInnerPage = data.allStrapiFaqInnerPage
  return (
    <Layout title="faq" /* singlePost={false} */>
      <div className='faq'>
        <Hero menImage={menImage} nodes={nodes[0]} />
      </div>
      <div className='singlePostContainer'>
        <div className="singlePostContent">
          <div className="singlePostCard faqCard">
            <h2>
              {faqInnerPage.title}
            </h2>
            <TextTemplate post={{ Description: faqInnerPage.content, PostImage: faqInnerPage.featured_image }} />
          </div>
        </div>
      </div>
      <ReadyToNextStep />
    </Layout>
  )
}

export default FaqPages;